import * as React from 'react';
import { SVGProps } from 'react';
const SvgCloseBig = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.295 5.705a.997.997 0 0 0-1.41 0L12 10.59 7.115 5.705a.997.997 0 0 0-1.41 1.41L10.59 12l-4.885 4.885a.997.997 0 0 0 1.41 1.41L12 13.41l4.885 4.885a.997.997 0 1 0 1.41-1.41L13.41 12l4.885-4.885a.997.997 0 0 0 0-1.41Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCloseBig;
