import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowM = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.222 7.758a1 1 0 0 0 0 1.414l7.07 7.07a1 1 0 0 0 1.415 0l7.071-7.07a1 1 0 1 0-1.414-1.415L12 14.122 5.636 7.757a1 1 0 0 0-1.414 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowM;
