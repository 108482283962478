import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgLoader = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_loader_svg__a)">
        <path
          d="M4.5 12c0 .412-.338.75-.75.75h-3A.752.752 0 0 1 0 12c0-.412.338-.75.75-.75h3c.412 0 .75.338.75.75Zm7.5 7.5a.752.752 0 0 0-.75.75v3c0 .413.338.75.75.75s.75-.337.75-.75v-3a.752.752 0 0 0-.75-.75Zm4.762-.712c-.224-.375-.675-.488-1.012-.263-.375.225-.488.675-.262 1.013l1.5 2.587c.224.375.675.488 1.012.262.375-.224.488-.675.262-1.012l-1.5-2.587Zm5.363-1.8-2.587-1.5a.727.727 0 0 0-1.013.262.727.727 0 0 0 .263 1.012l2.587 1.5c.337.188.825.075 1.012-.262a.727.727 0 0 0-.262-1.012Zm1.125-5.738h-3a.752.752 0 0 0-.75.75c0 .412.337.75.75.75h3c.413 0 .75-.338.75-.75a.752.752 0 0 0-.75-.75Zm-3.712-2.738 2.587-1.5c.375-.224.488-.674.262-1.012-.224-.375-.675-.487-1.012-.263l-2.587 1.5c-.375.226-.488.675-.263 1.013.188.375.638.488 1.013.262ZM18 1.612a.727.727 0 0 0-1.012.263l-1.5 2.588a.727.727 0 0 0 .262 1.012c.337.188.825.075 1.012-.262l1.5-2.588A.706.706 0 0 0 18 1.613ZM12 0a.752.752 0 0 0-.75.75v3c0 .412.338.75.75.75s.75-.338.75-.75v-3A.752.752 0 0 0 12 0ZM7.013 1.875C6.787 1.5 6.338 1.387 6 1.613c-.375.224-.487.675-.263 1.012l1.5 2.588c.226.375.675.487 1.013.262.375-.225.488-.675.262-1.012l-1.5-2.588Zm-1.8 5.362-2.588-1.5A.727.727 0 0 0 1.613 6a.727.727 0 0 0 .262 1.013l2.588 1.5c.337.187.824.075 1.012-.263.225-.375.113-.825-.262-1.013Z"
          fill="#B24ABF"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgLoader;
