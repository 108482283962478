import * as React from 'react';
import { SVGProps } from 'react';
const SvgSee = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 14.145a2.31 2.31 0 1 0 0-4.619 2.31 2.31 0 0 0 0 4.62Z" fill="currentColor" />
    <path
      d="M22.688 10.533a12.009 12.009 0 0 0-21.376 0 2.873 2.873 0 0 0 0 2.605 12.01 12.01 0 0 0 21.376 0 2.873 2.873 0 0 0 0-2.605ZM12 15.993a4.157 4.157 0 1 1 4.157-4.157A4.167 4.167 0 0 1 12 15.993Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSee;
