import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowS = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.293 15.006a1 1 0 0 0 1.414 0l4.597-4.597a1 1 0 1 0-1.414-1.413L12 12.888 8.11 8.996a1 1 0 1 0-1.413 1.413l4.596 4.597Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowS;
