import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgDelete = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_delete_svg__a)">
        <path
          d="M18.055 8.25a.788.788 0 0 1 .69.747v10.498c-.007 1.165-1.007 2.223-2.22 2.247-3.008.018-6.018 0-9.027 0-1.176-.008-2.239-1.012-2.246-2.247V8.997c.013-.358.28-.685.632-.74a.774.774 0 0 1 .857.623c.008.052.008.065.01.117 0 3.502-.023 7.003 0 10.505a.77.77 0 0 0 .74.74 720.6 720.6 0 0 0 9.015 0 .773.773 0 0 0 .74-.752V8.997c.015-.36.28-.685.633-.74.111-.018.14-.008.176-.007Zm-4.53 1.498a.646.646 0 0 1 .245.056.764.764 0 0 1 .458.608c.004.038.003.047.005.084v5.998a.653.653 0 0 1-.074.326c-.222.458-.963.565-1.287.107a.791.791 0 0 1-.14-.433v-5.998a.652.652 0 0 1 .044-.247.763.763 0 0 1 .623-.498c.08-.008.1-.004.126-.003Zm-2.969 0a.642.642 0 0 1 .245.056.764.764 0 0 1 .458.608c.004.038.004.047.005.084v5.998a.645.645 0 0 1-.075.326c-.22.458-.962.565-1.287.107a.79.79 0 0 1-.138-.433v-5.998a.64.64 0 0 1 .042-.247.763.763 0 0 1 .624-.498c.081-.008.1-.004.126-.003Zm-2.306-4.5V3a.645.645 0 0 1 .042-.248.765.765 0 0 1 .624-.497c.037-.004.047-.004.083-.005h5.998a.646.646 0 0 1 .287.057.762.762 0 0 1 .458.609c.004.037.004.046.005.084v2.248h3.748l.066.003c.057.008.072.008.128.023.557.15.737 1.053.18 1.374-.103.06-.16.09-.374.1H4.502c-.058-.003-.073-.001-.13-.011-.588-.104-.827-1.052-.245-1.388.104-.06.162-.09.375-.1H8.25Zm5.997 0V3.75H9.75v1.5h4.498Z"
          fill="#A8A8A8"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDelete;
